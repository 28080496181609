import React, { useEffect, useState , useRef} from 'react'
import WebSiteLoader from '../components/WebSiteLoader'
import Logo from "../assets/Logo.png"
import backgroundVid from "../assets/background.mp4"
import scrollDown from "../assets/scroll-down-ffffff.svg"
import vdPoster from "../assets/videoPoster.png"

import { FaPlay  } from "react-icons/fa6";
import { TiVideo } from "react-icons/ti";
import { MdDesignServices ,MdCallEnd} from "react-icons/md";
import { FaLaptopCode , FaInstagram , FaFacebook ,FaTwitter,FaLinkedin  } from "react-icons/fa";
import { SiMaterialdesignicons } from "react-icons/si";
import { IoIosMailOpen } from "react-icons/io";
import emailjs from '@emailjs/browser';
import CustomCursor from 'custom-cursor-react';
import 'custom-cursor-react/dist/index.css';
import MileStone from '../components/MileStone'

function Home() {
    const [isMenuOppend, setisMenuOppend] = useState(false)
    const [y, setY] = useState(window.scrollY);
    const [ introVidPaused , setIntroVidPaused ] = useState(true)
    const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_d040yym', 'template_srj27pr', form.current, {
        publicKey: 'Rj9g88N2nE6dJHjZB',
      })
      .then(
        () => {
          console.log("Done");
          form.current.reset()
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };
    useEffect(() => {
        
        window.addEventListener("scroll", (e) => setisMenuOppend(false));
        // Create fullscreen video button
        function toggleFullscreen() {
            if (!document.webkitFullscreenElement) {
            if (document.getElementById("introVid").requestFullScreen) {
                document.getElementById("introVid").requestFullScreen();
            } else if (document.getElementById("introVid").webkitRequestFullScreen) {
                document.getElementById("introVid").webkitRequestFullScreen();
            } else if (document.getElementById("introVid").mozRequestFullScreen) {
                document.getElementById("introVid").mozRequestFullScreen();
            }
            } else {
            document.webkitExitFullscreen();
            }
        }
        
        document.getElementById("introVid").addEventListener("dblclick", toggleFullscreen);
        return () => { // return a cleanup function to unregister our function since it will run multiple times
            window.removeEventListener("scroll", (e) => setisMenuOppend(false));
        };
    }, [y]);
    const PlayPauseVid = () => {
        const player = document.getElementById("introVid")
        if(player?.paused) {
            player.play()
            setIntroVidPaused(false)
        }else {
            player.pause()
            setIntroVidPaused(true)
        }
    }

    // TVIDS HANDLER
  const vid1Ref = useRef(null);
  const vid2Ref = useRef(null);
  const vid3Ref = useRef(null);
  const vid4Ref = useRef(null);
  const firstVidLoad = useRef(1);
  const [currentVideo, setCurrentVideo] = useState(null);

  const handleVideoClick = (ref) => {
    firstVidLoad.current = 0
    if (currentVideo && currentVideo !== ref) {
      currentVideo.current.pause();
    }

    if (currentVideo !== ref) {
      ref.current.play();
      ref.current.style.filter = "none"
      setCurrentVideo(ref);
    } else {
      ref.current.paused ? ref.current.play() : ref.current.pause();
    }
  };
  return (
    <div className="Home">
        <CustomCursor
            targets={['.link', '.cTarget']}
            customClass='custom-cursor'
            dimensions={30}
            fill='#2596be'
            smoothness={{
                movement: 0.2,
                scale: 0.1,
                opacity: 0.2,
            }}
            targetOpacity={0.5}
        />
        <WebSiteLoader/>

        {isMenuOppend && <div className="menu">
            
            <div>
                <a href="#Hero" >Home</a>
                <a href="#AboutUs" >About Us</a>
                <a href="#Services" >Services</a>
                <a href="#MileStones" >MileStones</a>
                <a href="#Testimonials" >Testimonials</a>
            </div>
        </div>}

        <div className="Hero" id='Hero'>

            <video autoPlay muted loop src={backgroundVid} oreload="none"></video>

            <nav>
                <div className="innerNavBar">
                    <div className="menuIcon" style={{zIndex:9999999}} onClick={()=>setisMenuOppend(!isMenuOppend)}>
                        <span></span>
                        <span></span>
                        <span style={{width : isMenuOppend ? "50%" : "0px",backgroundColor :"#2596be"}}></span>
                    </div>
                    <img className="logo" src={Logo} alt='logo'></img>
                    <button className='cTarget' onClick={()=>window.location.href = "https://calendly.com/noblecraft/golden"}>Get In Touch</button>
                </div>
            </nav>

            <h1 className="heroTitle cTarget" >Create The Highest Quality Videos Possible <button onClick={()=>window.location.href = "https://calendly.com/noblecraft/golden"}>Get In Touch</button></h1>
            <img alt='scrollDown' src={scrollDown} className="scrollDown cTarget" onClick={()=>document.getElementById("AboutUs").scrollIntoView()}></img>
        </div>
        
        <div className="AboutUs" id="AboutUs">
            <h1>About Us</h1>
            <div className="backgroundText">
                <span className='prevent-select'>About Us</span>
                <span className='prevent-select' id='middleTextAboutUs'>About Us</span>
                <span className='prevent-select' id='middleTextAboutUs'>About Us</span>
            </div>
            <div className="innerAboutUs">
                <div onClick={() => handleVideoClick(vid4Ref)} className='cTarget'>
                    <video ref={vid4Ref}  src={"https://royalty-craft.pages.dev/Nidhal.mp4"} loop poster={vdPoster} id="introVid"></video>
                    {(vid4Ref?.current?.paused || firstVidLoad.current == 1 ) && <FaPlay className="playPauseBtn"/>}
                </div>
                <div className="AboutUsTextHolder">
                    <h2>Our Story</h2>
                    <p>A young and diverse agency, Noble Craft brings together a team of professionals, including marketers, video editors, designers, and developers. Established in 2022, our strong work ethic and professional expertise are what set Noble Craft apart. Our royal approach is adaptive, ensuring that you will always stay ahead of your competition and achieve the maximum possible results.</p>
                </div>
            </div>
        </div>

        <div className="Services" id="Services">
            <h1>Services</h1>
            <div className='innerServices'>
                <section className="dotsHolder">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </section>
                <div>
                    <div className="service cTarget">
                        <TiVideo />
                        <label>Video Editing</label>
                        
                    </div>
                    <div className="service cTarget">
                        <MdDesignServices />
                        <label>Creative & Design</label>
                        
                    </div> 
                    <div className="service cTarget">
                        <SiMaterialdesignicons />
                        <label>Web Design</label>
                    </div>
                    <div className="service cTarget" id='serviceMinS'>
                        <FaLaptopCode />
                        <label>Web Development</label>
                    </div>
                </div>

                <div>
                    <div className="service cTarget" id='serviceBigS'>
                        <FaLaptopCode />
                        <label>Web Development</label>
                    </div> 
                    <div className="service cTarget">
                        <IoIosMailOpen />
                        <label>Digital Marketing</label>
                        
                    </div> 
                    <div className="service cTarget">
                        <MdCallEnd />
                        <label>Strategy & Consulting</label>
                        
                    </div>
                </div>

            </div>
        </div>
        <div className='Pricing' id='Pricing'>
            <h1>Plans</h1>
            <div className='bigCercleInPricing'></div>
            <div className='bigCercleInPricing2'></div>
            <div className='pracingCardsHolder'>
                <div className='pracingCard'>
                    <h1>$900<span>/Month</span></h1>
                    {/* <h2 style={{color:"#C0C0C0",borderColor : "#C0C0C0"}}>Silver</h2> */}
                    <div className='pracingCardInfos'>
                        <label><span>1</span>30 shorts</label>
                        <label><span>2</span>content ideas+ scripts</label>
                        <label><span>3</span>thumbnail for each video</label>
                        <label><span>4</span>logo animation</label>
                        <label><span>5</span>unlimited revisions</label>
                        <label><span>6</span>digital assets</label>
                        <label><span>7</span>virtual assistant</label>
                        <label><span>8</span>24/7 support</label>
                        <label><span>9</span>managed through Slack</label>
                        <label><span>10</span>pause or cancel anytime</label>
                    </div>
                    <a className='normalpricing' href='https://calendly.com/noblecraft/golden'>Order Now</a>
                </div>
                <div className='pracingCard'>
                <span class="cercelShape"></span>
                    <h1>$1900<span>/Month</span></h1>
                    <span class="cercelShape2"></span>
                    {/* <h2 style={{color : "#b9f2ff",borderColor : "#b9f2ff"}}>Diamond</h2> */}
                    <div className='pracingCardInfos'>
                        <label><span>1</span>30 shorts</label>
                        <label><span>2</span>4 long-form content pieces</label>
                        <label><span>3</span>content ideas+ scripts</label>
                        <label><span>4</span>thumbnail for each video</label>
                        <label><span>5</span>titel & description</label>
                        <label><span>6</span>logo animation</label>
                        <label><span>7</span>unlimited revisions</label>
                        <label><span>8</span>digital assets</label>
                        <label><span>9</span>virtual assistant</label>
                        <label><span>10</span>24/7 support</label>
                        <label><span>11</span>managed through Slack</label>
                        <label><span>12</span>pause or cancel anytime</label>
                    </div>
                    <a href='https://calendly.com/noblecraft/golden'>Order Now</a>
                </div>
                <div className='pracingCard'>
                
                    <h1>$1000<span>/Month</span></h1>
                    {/* <h2 style={{color:"#ffef98",borderColor : "#ffef98"}}>Golden</h2> */}
                    <div className='pracingCardInfos'>
                        <label><span>1</span>4 long-form content pieces</label>
                        <label><span>2</span>content ideas+ scripts</label>
                        <label><span>3</span>titel & discription</label>
                        <label><span>4</span>thumbnail for each video</label>
                        <label><span>5</span>logo animation</label>
                        <label><span>6</span>unlimited revisions</label>
                        <label><span>7</span>digital assets</label>
                        <label><span>8</span>virtual assistant</label>
                        <label><span>9</span>24/7 support</label>
                        <label><span>10</span>managed through Slack</label>
                        <label><span>11</span>pause or cancel anytime</label>
                    </div>
                    <a className='normalpricing' href='https://calendly.com/noblecraft/golden'>Order Now</a>
                </div>
            </div>
        </div>
        <div className="MileStones" id='MileStones'>
            <div className="innerMileStonesHolder">
                <span className='cercelShape'></span>
                <span className='cercelShape2'></span>
                <h1 className='cTarget'>MileStones</h1>
                <MileStone socialMediaUrl="https://www.instagram.com/faridsformula" images={["https://royalty-craft.pages.dev/Farid_.jpg","https://royalty-craft.pages.dev/IMG_20240122_155647_801.jpg","https://royalty-craft.pages.dev/Farid(1).jpg"]} clientName="Farid Moaveni"/>
                <MileStone socialMediaUrl="https://www.instagram.com/nickmirabella" images={["https://royalty-craft.pages.dev/Nick(1).jpg","https://royalty-craft.pages.dev/Nick.jpg",require("../assets/s3.jpg")]} clientName="Nick Mirabella"/>
            </div>
        </div>

        <div className='Testimonials' id='Testimonials'>
            <h1 className='cTarget'>Testimonials</h1>
            <div className="innerTestimonialsHolder">
                <span className='testishape'></span>
                <div>
                    <video onClick={() => handleVideoClick(vid1Ref)} ref={vid1Ref} width="750" height="500" loading="lazy" preload='true' loop className='TVIDS cTarget'>
                        <source src={require("../assets/Nick Mirabella owner of the warehouse salon.mp4")} type="video/mp4"/>
                    </video>
                    {(vid1Ref?.current?.paused || firstVidLoad.current == 1 ) && <FaPlay className="playPauseBtn cTarget" onClick={() => handleVideoClick(vid1Ref)}/>}
                </div>
                <div>
                    <video onClick={() => handleVideoClick(vid2Ref)} ref={vid2Ref} width="750" height="500" loading="lazy" preload='true' loop className='TVIDS cTarget'>
                        <source src={require("../assets/Farid leader at google.mp4")} type="video/mp4"/>
                    </video>
                    {(vid2Ref?.current?.paused || firstVidLoad.current == 1) &&<FaPlay className="playPauseBtn cTarget" onClick={() => handleVideoClick(vid2Ref)}/>}
                </div>
                <div>
                    <video onClick={() => handleVideoClick(vid3Ref)} ref={vid3Ref} width="750" height="500" loading="lazy" preload='true' loop className='TVIDS cTarget'>
                        <source src={require("../assets/Brandon OConnell.mp4")} type="video/mp4"/>
                    </video>
                    {(vid3Ref?.current?.paused || firstVidLoad.current == 1) && <FaPlay className="playPauseBtn cTarget" onClick={() => handleVideoClick(vid3Ref)}/>}
                </div>

            </div>
        </div>

        <div className="ContactUs">
            <div className='innerContactUs'>
                <form ref={form} onSubmit={sendEmail}>
                    <h1>Contact Us</h1>
                    <div>
                        <label>Full Name</label>
                        <input type='text' placeholder='Type Full Name...'></input>
                    </div>
                    <div>
                        <label>Email</label>
                        <input type='email' placeholder='Type Email...'></input>
                    </div>
                    <div>
                        <label>Message</label>
                        <textarea placeholder='Type Message...'></textarea>
                    </div>
                    <input type='submit' className='submitInContact'></input>
                </form>
                <div className='contactUsSus'>
                    <h1>We'd Love to Hear From You.</h1>
                    <span></span>
                    <span></span>
                    <span></span>
                    <div></div>
                </div>
            </div>
        </div>

        <footer>
            <div className="innerFooter">
                <img className='cTarget' src={require("../assets/Logo.png")}></img>
                <div className='contactList'>
                    <FaInstagram className='cTarget' onClick={()=>window.location.href = "https://www.instagram.com/nidhal_page/"}/>
                    <FaFacebook className='cTarget' onClick={()=>window.location.href = "https://www.facebook.com/profile.php?id=100007301896195"}/>
                    <FaTwitter className='cTarget' onClick={()=>window.location.href = "https://x.com/NewsNidhal?t=9zHtXl9YJHXuNf1NLUSIOQ&s=09"}/>
                    <FaLinkedin className='cTarget' onClick={()=>window.location.href = "https://www.linkedin.com/in/nidhal-amdouni-20a805296/?originalSubdomain=tn"}/>
                </div>
            </div>
        </footer>
    </div>
  )
}

export default Home