import * as React from "react";
import { createRoot } from "react-dom/client";
import "./style/main.css";
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import Home from "./pages/Home";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home/>,
  }
]);

createRoot(document.getElementById("root")).render(
  <RouterProvider router={router} />
);