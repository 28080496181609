import React from 'react'
import { GrInstagram } from "react-icons/gr";
function MileStone({images,socialMediaUrl,clientName}) {
  return (
    <div className="MileStone">
        <a href={socialMediaUrl} className='cTarget'><GrInstagram />{clientName}</a>
        <div className="MileStoneImages">
            <div className='cTarget'>
                <span>01</span>
                <img src={images[0]} onClick={()=>window.location.href = socialMediaUrl}></img>
            </div>
            <div className='cTarget'>
                <span>02</span>
                <img src={images[1]} onClick={()=>window.location.href = socialMediaUrl}></img>
            </div>
            <div className='cTarget'>
                <span>03</span>
                <img src={images[2]} onClick={()=>window.location.href = socialMediaUrl}></img>
            </div>
        </div>
    </div>
  )
}

export default MileStone