import React from 'react'

function WebSiteLoader() {
  return (
    <div className="WebSiteLoader">
        <h1>NobleCraft</h1>
        <h2>Craft Your Vision</h2>
    </div>
  )
}

export default WebSiteLoader